import React from 'react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import { ReportPicker as BaseReportPicker } from './ReportPicker.GridDropdown';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { SequenceComparator } from '../../comparators/SequenceComparator';
export const ReportPicker = ({ onReportSelectionChanged, portfolioId, report }) => {
    const { useListView, SystemReportPickerListViewId } = useApplicationContext();
    const listViewResponse = useListView(SystemReportPickerListViewId);
    if (listViewResponse.isLoading) {
        return React.createElement(Spinner, null);
    }
    if (listViewResponse.isError || !listViewResponse.data) {
        return (React.createElement(MessageBar, { text: `Error fetching ListView: ${SystemReportPickerListViewId}.`, messageBarType: MessageBarType.error }));
    }
    const gridFields = listViewResponse.data.listViewMembers.sort(SequenceComparator).map((lvm) => ({
        displayNameRule: lvm.scalarDisplayName,
        field: lvm.viewFieldName,
        type: lvm.viewFieldName === 'IncludesCurrencyExchange' ? 'yes/no' : undefined,
    }));
    return (React.createElement(BaseReportPicker, { fields: gridFields, onReportSelectionChanged: onReportSelectionChanged, portfolioId: portfolioId, report: report }));
};
export default ReportPicker;
