import React from 'react';
import { ReportCatalogContent as BaseReportCatalogContent } from './ReportCatalogContent.State';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { ExpressionBuilder } from '../../builders/ExpressionBuilder/ExpressionBuilder';
export const ReportCatalogContent = ({ domain, portfolioId, report, onChange, currencyExchange, }) => {
    const { SystemPortfolioIdExpression, SystemPortfolioIdExpressionAlt, SystemPortfolioIdsExpression } = useApplicationContext();
    const portfolioFilterExpression = new ExpressionBuilder(`${SystemPortfolioIdExpression} = '${portfolioId}'`)
        .and(`${SystemPortfolioIdExpressionAlt} = '${portfolioId}'`)
        .and(`DOESCONTAIN('${portfolioId}', ${SystemPortfolioIdsExpression})`)
        .build(domain);
    return (React.createElement(BaseReportCatalogContent, { portfolioFilterExpression: portfolioFilterExpression, portfolioId: portfolioId, report: report, onChange: onChange, currencyExchange: currencyExchange }));
};
export default ReportCatalogContent;
