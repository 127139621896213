import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { ReportCatalogContent as BaseReportCatalogContent } from './ReportCatalogContent.PortfolioFilter';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
export const ReportCatalogContent = ({ portfolioId, report, onChange, currencyExchange, }) => {
    const { useDomain } = useApplicationContext();
    const domainQuery = useDomain(report.DomainId);
    if (domainQuery.isLoading) {
        return React.createElement(Spinner, { "data-testid": "spinner-ReportCatalogContent", size: SpinnerSize.large });
    }
    if (domainQuery.isError || !domainQuery.data) {
        return React.createElement(MessageBar, { text: "Error Resolving Domain.", messageBarType: MessageBarType.error });
    }
    return (React.createElement(BaseReportCatalogContent, { domain: domainQuery.data, portfolioId: portfolioId, report: report, onChange: onChange, currencyExchange: currencyExchange }));
};
export default ReportCatalogContent;
