import { useQuery } from 'react-query';
import { useExecuteLiftAndSiftQuery } from '../useExecuteLiftAndSiftQuery/useExecuteLiftAndSiftQuery';
import { ReportsExecuteRequestBuilder } from '../../builders/ReportsExecuteRequestBuilder/ReportsExecuteRequestBuilder';
export const REPORTS_KEY = 'Reports';
export const useReports = (portfolioId) => {
    const [executeQuery] = useExecuteLiftAndSiftQuery();
    const requestBody = new ReportsExecuteRequestBuilder()
        .withFilterByOptionalPortfolioId(portfolioId)
        .build();
    const queryResult = useQuery([REPORTS_KEY, portfolioId], () => executeQuery(requestBody), {
        retry: false,
    });
    return queryResult;
};
export default useReports;
