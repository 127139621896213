import React from 'react';
import { Spinner } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { AddRoleReportPanel as BaseAddRoleReportPanel } from './AddRoleReportPanel.InitialFormData';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { FormPanel } from '../FormPanel/FormPanel';
export const AddRoleReportPanel = ({ filterExpression, filterIds, isOpen, onCancel, onSubmitAndClose, reportId, currencyExchange, }) => {
    const { useFilters } = useApplicationContext();
    const filtersRequest = useFilters(filterIds);
    if (filtersRequest.isLoading) {
        return React.createElement(Spinner, { "data-testid": "spinner-AddRoleReportPanel" });
    }
    if (filtersRequest.isError) {
        return React.createElement(MessageBar, { text: "Error fetching selected filters.", messageBarType: MessageBarType.error });
    }
    return (React.createElement(FormPanel, { isOpen: isOpen, onCancel: onCancel, onSubmitAndClose: onSubmitAndClose },
        React.createElement(BaseAddRoleReportPanel, { filterExpression: filterExpression, filters: filtersRequest.data, reportId: reportId, currencyExchange: currencyExchange })));
};
export default AddRoleReportPanel;
