var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { mergeHeaders } from '@samc/react-ui-request';
import { Fetch as _Fetch } from '../Fetch/Fetch';
import { UriBuilder } from '../../builders/UriBuilder/UriBuilder';
export const GenerateReport = (api_1, domainId_1, viewId_1, reportId_1, source_1, reportPeriodStart_1, targetCurrency_1, includesCurrencyExchange_1, filterExpression_1, requestInit_1, templateId_1, sortOrder_1, sortOrderBy_1, ...args_1) => __awaiter(void 0, [api_1, domainId_1, viewId_1, reportId_1, source_1, reportPeriodStart_1, targetCurrency_1, includesCurrencyExchange_1, filterExpression_1, requestInit_1, templateId_1, sortOrder_1, sortOrderBy_1, ...args_1], void 0, function* (api, domainId, viewId, reportId, source, reportPeriodStart, targetCurrency, includesCurrencyExchange, filterExpression, requestInit, templateId, sortOrder, sortOrderBy, filterIds = [], fetch = _Fetch) {
    const uri = new UriBuilder(`${api}/api/v1/Generate/report/${reportId}`).withSort(sortOrder, sortOrderBy).build();
    try {
        const response = yield fetch(uri, {
            method: 'POST',
            headers: mergeHeaders(requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, {
                'Content-Type': 'application/json; charset=utf-8',
            }),
            body: JSON.stringify({
                domainId,
                reportId,
                filterExpression,
                filterIds,
                templateId,
                viewId,
                source,
                reportPeriodStart,
                targetCurrency,
                includesCurrencyExchange,
            }),
        });
        return response;
    }
    catch (e) {
        const err = e;
        throw new Error(`${err.message}`);
    }
});
export default GenerateReport;
