import React from 'react';
import { styled } from 'styletron-react';
const StyledBorder = styled('div', () => ({
    borderTop: '1px solid #d1d1d1',
    marginTop: '5px',
    paddingTop: '10px',
}));
export const HorizontalRule = () => {
    return React.createElement(StyledBorder, null);
};
export default HorizontalRule;
