import React from 'react';
import { GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { ExpressionBuilder } from '../../builders/ExpressionBuilder/ExpressionBuilder';
export const RoleReportsGrid = () => {
    const { CurrentUser, SystemRoleReportsListViewId } = useApplicationContext();
    const [selectedListViewId, setSelectedListViewId] = React.useState(SystemRoleReportsListViewId);
    const filterByUserExpression = new ExpressionBuilder(`[UserId] = '${CurrentUser === null || CurrentUser === void 0 ? void 0 : CurrentUser.id.toString()}'`).build();
    return (React.createElement(GridScreenWrapper, { filters: [filterByUserExpression], gridDisplayOptions: { suppressAddRowButton: true, suppressEditToggle: true }, listViewId: selectedListViewId, setListViewId: setSelectedListViewId, ServiceDrivenViewSet: ServiceDrivenViewSet }));
};
export default RoleReportsGrid;
