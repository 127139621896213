import React from 'react';
import { ScheduleRoleReportForm as BaseScheduleRoleReportForm } from './ScheduleRoleReportForm.FormScreen';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { CronField } from '../CronField/CronField';
export const ScheduleRoleReportForm = ({ id }) => {
    const { SystemScheduleRoleReportFormId, useFormView } = useApplicationContext();
    const formViewRequest = useFormView(SystemScheduleRoleReportFormId);
    const useFormViewOverride = (_formViewId) => {
        const { data } = formViewRequest;
        if (data) {
            data.formViewMembers.CronSchedule.customRenderer = CronField;
            data.formViewMembers.CronSchedule.editableExpression = 'true';
        }
        return {
            isError: formViewRequest.isError,
            isLoading: formViewRequest.isLoading,
            data: formViewRequest.data,
            refresh: formViewRequest.refresh,
            error: formViewRequest.error,
        };
    };
    return React.createElement(BaseScheduleRoleReportForm, { id: id, useFormView: useFormViewOverride });
};
export default ScheduleRoleReportForm;
