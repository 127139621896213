import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from '@samc/react-ui-core';
import singleSpaReact from 'single-spa-react';
import { AsyncExpressionEvaluatorInstance } from '@samc/expressions-core';
import type { LifeCycleFn } from 'single-spa';
import { Root, SingleSpaProps } from './root.component';

const lifecycles = singleSpaReact<SingleSpaProps>({
  renderType: 'createRoot',
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundaryClass: ErrorBoundary as never,
});

export const { bootstrap } = lifecycles;

export const mount: LifeCycleFn<SingleSpaProps> = async (...params) => {
  await AsyncExpressionEvaluatorInstance.initialize(20);
  return lifecycles.mount(...params);
};

export const unmount: LifeCycleFn<SingleSpaProps> = (...params) => {
  AsyncExpressionEvaluatorInstance.dispose();
  return lifecycles.unmount(...params);
};
