import React from 'react';
import { ToastMessage, toastSuccess, MessageBar, MessageBarType } from '@samc/react-ui-core';
import { TaskStatus, useApplicationContext } from '@samc/screen-config-api';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { useHistoryContext } from '@samc/react-ui-history';
import { apiProvider } from '../../../ReportApi/api/Requests';
import { useApiContext } from '../../../ReportApi/Context/useApiContext';
import { DefaultValidationResponse, getValidationResponse } from '../../../static/ReportUtil';
import { useStandardReport } from '../../../ReportApi/useStandardReport/useStandardReport';
export const CloneReport = ({ refreshViewSet, updateAction, setisLoading, props, }) => {
    var _a, _b, _c;
    const { browserHistory } = useHistoryContext();
    const [validationResponse, setValidationResponse] = React.useState(Object.assign({}, DefaultValidationResponse));
    const { currentUser, Entitlements } = useApplicationContext();
    if (!currentUser.hasEntitlement(Entitlements.ManageReports))
        return (React.createElement("div", null,
            React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Error loading Clone Reports", text: "You are not authorized to view this page. Please contact your administrator if you believe this is an error." })));
    const onErrorPopupClose = () => {
        updateAction(undefined);
        setValidationResponse(Object.assign(Object.assign({}, validationResponse), { messages: [], childResponses: {} }));
    };
    const adhocmembers = [
        {
            scalarDisplayName: 'Description',
            scalarExpression: '[Description]',
        },
        {
            scalarDisplayName: 'IsActive',
            scalarExpression: '[IsActive]',
        },
        {
            scalarDisplayName: 'ReportTemplateStrataId',
            scalarExpression: '[ReportTemplateStrataId]',
        },
        {
            scalarDisplayName: 'ReportImageStrataId',
            scalarExpression: '[ReportImageStrataId]',
        },
        {
            scalarDisplayName: 'DataLevel',
            scalarExpression: '[DataLevel]',
        },
        {
            scalarDisplayName: 'ListViewId',
            scalarExpression: '[ListViewId]',
        },
        {
            scalarDisplayName: 'FilterId',
            scalarExpression: '[FilterId]',
        },
        {
            scalarDisplayName: 'ValidationExpression',
            scalarExpression: '[ValidationExpression]',
        },
        {
            scalarDisplayName: 'ValidationText',
            scalarExpression: '[ValidationText]',
        },
        {
            scalarDisplayName: 'Source',
            scalarExpression: '[Source]',
        },
        {
            scalarDisplayName: 'ReportPeriodStart',
            scalarExpression: '[ReportPeriodStart]',
        },
        {
            scalarDisplayName: 'TargetCurrency',
            scalarExpression: '[TargetCurrency]',
        },
        {
            scalarDisplayName: 'IncludesCurrencyExchange',
            scalarExpression: '[IncludesCurrencyExchange]',
        },
    ];
    const adhocmembersList = ((_a = props.domainId) === null || _a === void 0 ? void 0 : _a.includes('REPORT_StandardReport'))
        ? adhocmembers
        : [
            ...[
                {
                    scalarDisplayName: 'StandardReportId',
                    scalarExpression: '[StandardReportId]',
                },
            ],
            ...adhocmembers,
        ];
    const { data: standardReportData, isLoading: isLoadingParticipants } = useStandardReport(props.primaryKeyValue, props.domainId, ((_b = props.domainId) === null || _b === void 0 ? void 0 : _b.includes('REPORT_StandardReport')) || ((_c = props.domainId) === null || _c === void 0 ? void 0 : _c.includes('REPORT_Report'))
        ? adhocmembersList
        : [], `CloneReport`);
    const payload = standardReportData === null || standardReportData === void 0 ? void 0 : standardReportData.Data.reduce((p, item, index) => {
        var _a;
        return (Object.assign(Object.assign({}, p), { [`${-index - 1}`]: {
                __ADDED__: true,
                Id: -1,
                StandardReportId: ((_a = props.domainId) === null || _a === void 0 ? void 0 : _a.includes('REPORT_Report')) ? item.StandardReportId : item.Id,
                Name: `${item.Name}-Clone`,
                Description: item.Description,
                DomainId: item.DomainId,
                IsActive: false,
                ReportTemplateStrataId: item.ReportTemplateStrataId,
                ReportImageStrataId: item.ReportImageStrataId,
                DataLevel: item.DataLevel,
                ListViewId: item.ListViewId,
                FilterId: item.FilterId,
                ValidationExpression: item.ValidationExpression,
                ValidationText: item.ValidationText,
                IncludesCurrencyExchange: item.IncludesCurrencyExchange,
                Source: item.Source,
                TargetCurrency: item.TargetCurrency,
                ReportPeriodStart: item.ReportPeriodStart,
            } }));
    }, {});
    const api = useApiContext();
    React.useEffect(() => {
        var _a;
        setisLoading(true);
        if (!isLoadingParticipants) {
            apiProvider
                .submitTask('REPORT_Report', 'REPORT_Report', 'Clone Report', payload, (_a = api.requestInit) !== null && _a !== void 0 ? _a : {}, false)
                .then(([response, request]) => {
                if (response.statusCode !== TaskStatus.Completed ||
                    response.messageCount > 0 ||
                    validationResponse.prompts.length > 0) {
                    setValidationResponse(getValidationResponse(request, response));
                }
                else {
                    toastSuccess(React.createElement(ToastMessage, { title: "Success", message: "Report cloned successfully." }));
                    updateAction(undefined);
                    refreshViewSet();
                    browserHistory.push('/screen/#/screens/REPORT_StandardReport/PortfolioReports');
                }
            })
                .finally(() => {
                setisLoading(false);
            });
        }
    }, [isLoadingParticipants]);
    const { request } = validationResponse;
    const requests = React.useMemo(() => [request], [request]);
    const responses = React.useMemo(() => [validationResponse], [validationResponse]);
    return (React.createElement(TaskResponsePopup, { requests: requests, responses: responses, visible: validationResponse.messages.length > 0 ||
            validationResponse.prompts.length > 0 ||
            Object.values(validationResponse.childResponses).some(({ messages }) => messages.length > 0), setVisible: onErrorPopupClose }));
};
export default CloneReport;
