import React from 'react';
import { Modal } from '@fluentui/react';
import { SitusHeader } from '@samc/react-ui-core';
import { styled } from 'styletron-react';
import { ReportImage } from '../ReportImage/ReportImage';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
const StyledModalContainer = styled('div', () => ({
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
}));
const StyledImageContainer = styled('div', () => ({
    margin: '1rem',
}));
export const ReportImageModal = ({ isOpen, onClose, strataImageId }) => {
    var _a, _b;
    const { useReportImage } = useApplicationContext();
    const imageDownloadRequest = useReportImage(strataImageId);
    return (React.createElement(Modal, { isOpen: isOpen, layerProps: { eventBubblingEnabled: true } },
        React.createElement(StyledModalContainer, null,
            React.createElement(SitusHeader, { title: "Report Style", rejectionButtonText: "Close", onRejectionButtonClicked: onClose }),
            React.createElement(StyledImageContainer, null,
                React.createElement(ReportImage, { error: imageDownloadRequest.isError, src: (_a = imageDownloadRequest.data) === null || _a === void 0 ? void 0 : _a.downloadLink, alt: (_b = imageDownloadRequest.data) === null || _b === void 0 ? void 0 : _b.fileName })))));
};
export default ReportImageModal;
