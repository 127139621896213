import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { ReportFilterGrid as BaseReportFilterGrid } from './ReportFilterGrid.Grid';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
export const ReportFilterGrid = ({ filterIds, onGridColumnFilterExpressionChange, onGridColumnSortChange, portfolioFilterExpression, renderControlBarItems, report, }) => {
    const { useDomain, useListView } = useApplicationContext();
    const domainRequest = useDomain(report.DomainId);
    const listViewRequest = useListView(report.ListViewId);
    if (listViewRequest.isLoading || domainRequest.isLoading) {
        return React.createElement(Spinner, { size: SpinnerSize.large, "data-testid": "spinner" });
    }
    if (domainRequest.isError || listViewRequest.isError || !domainRequest.data || !listViewRequest.data) {
        return React.createElement(MessageBar, { text: "Error fetching metadata for grid.", messageBarType: MessageBarType.error });
    }
    return (React.createElement(BaseReportFilterGrid, { filterIds: filterIds, listView: listViewRequest.data, onGridColumnFilterExpressionChange: onGridColumnFilterExpressionChange, onGridColumnSortChange: onGridColumnSortChange, portfolioFilterExpression: portfolioFilterExpression, primaryKey: domainRequest.data.primaryKey, renderControlBarItems: renderControlBarItems, report: report }));
};
export default ReportFilterGrid;
