import React from 'react';
import { ApiProvider as CoreApiContextProvider, } from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
import { QueryClient as QueryClientClass, QueryClientProvider } from 'react-query';
import { useDomain as _useDomain, useDomainGetter as _useDomainGetter, useFormView as _useFormView, useListView as _useListView, } from '@samc/screen-config-api';
import { useGridFieldsFromListview as _useGridFieldsFromListView } from '@samc/screen-config-core/lib/hooks/useGridFieldsFromListview/useGridFieldsFromListview';
import { useHeaderContext as _useHeaderContext, useRouterBlockerAndPromptForUnsavedChanges as _useRouterBlockerForUnsavedChanges, } from '@samc/react-ui-history';
import { DownloadFile as _DownloadFile } from '../../functions/DownloadFile/DownloadFile';
import { ShowMessage as _ShowMessage } from '../../functions/ShowMessage/ShowMessage';
import { useExecuteLiftAndSiftQuery as _useExecuteLiftAndSiftQuery } from '../../hooks/useExecuteLiftAndSiftQuery/useExecuteLiftAndSiftQuery';
import { useFilters as _useFilters } from '../../hooks/useFilters/useFilters';
import { useGenerateReportMutation as _useGenerateReportMutation } from '../../hooks/useGenerateReportMutation/useGenerateReportMutation';
import { useReportImage as _useReportImage } from '../../hooks/useReportImage/useReportImage';
import { useReport as _useReport } from '../../hooks/useReport/useReport';
import { useReportValidation as _useReportValidation } from '../../hooks/useReportValidation/useReportValidation';
const _aggregatorApi = '/_apis/aggregator';
const _folderApi = '/_apis/aggregator/folderapi';
const _liftSiftApi = '/_apis/querybuilder';
const _reportingApi = '/_apis/reporting';
const _strataApi = '/_apis/strata';
const _taskApi = '/taskgateway';
const _valuationApi = '/_apis/valuation';
const _queryAuthorizationApi = '/_apis/query-authorization';
const _queryClient = new QueryClientClass();
const _systemInternalIngressPort = 8443;
const _systemMyReportDomainId = 'REPORT_MyReport';
const _systemMyReportsListViewId = 'REPORT_MyReportsGrid';
const _systemRoleReportDomainId = 'REPORT_RoleReport';
const _systemRoleReportsListViewId = 'REPORT_RoleReportsGrid';
const _systemOptionalPortfolioIdsExpression = '[Optional_Portfolio_Ids]';
const _systemPortfolioIdExpression = '[Portfolio_Id]';
const _systemPortfolioIdExpressionAlt = '[PortfolioId]';
const _systemPortfolioIdsExpression = '[Portfolio_Ids]';
const _systemReportDomainId = 'REPORT_Report';
const _systemReportPickerListViewId = 'REPORT_ReportPicker';
const _systemSaveAsRoleReportPanelFormId = 'REPORT_SaveAsRoleReportPanelForm';
const _systemSaveAsMyReportPanelFormId = 'REPORT_SaveAsMyReportPanelForm';
const _systemScheduleMyReportFormId = 'REPORT_ScheduleMyReportForm';
const _systemScheduleRoleReportFormId = 'REPORT_ScheduleRoleReportForm';
const _systemStandardReportDomainId = 'REPORT_StandardReport';
const _systemSummaryDisplayName = 'SummaryResult';
export const ApplicationContext = React.createContext({
    AggregatorApi: _aggregatorApi,
    CurrentUser: undefined,
    DownloadFile: _DownloadFile,
    FolderApi: _folderApi,
    LiftSiftApi: _liftSiftApi,
    QueryAuthorizationApi: _queryAuthorizationApi,
    QueryClient: _queryClient,
    ReportingApi: _reportingApi,
    requestInit: undefined,
    ShowMessage: _ShowMessage,
    StrataApi: _strataApi,
    SystemInternalIngressPort: _systemInternalIngressPort,
    SystemMyReportDomainId: _systemMyReportDomainId,
    SystemRoleReportDomainId: _systemRoleReportDomainId,
    SystemMyReportsListViewId: _systemMyReportsListViewId,
    SystemRoleReportsListViewId: _systemRoleReportsListViewId,
    SystemOptionalPortfolioIdsExpression: _systemOptionalPortfolioIdsExpression,
    SystemPortfolioIdExpression: _systemPortfolioIdExpression,
    SystemPortfolioIdExpressionAlt: _systemPortfolioIdExpressionAlt,
    SystemPortfolioIdsExpression: _systemPortfolioIdsExpression,
    SystemReportDomainId: _systemReportDomainId,
    SystemReportPickerListViewId: _systemReportPickerListViewId,
    SystemSaveAsMyReportPanelFormId: _systemSaveAsMyReportPanelFormId,
    SystemSaveAsRoleReportPanelFormId: _systemSaveAsRoleReportPanelFormId,
    SystemScheduleMyReportFormId: _systemScheduleMyReportFormId,
    SystemScheduleRoleReportFormId: _systemScheduleRoleReportFormId,
    SystemStandardReportDomainId: _systemStandardReportDomainId,
    SystemSummaryDisplayName: _systemSummaryDisplayName,
    TaskApi: _taskApi,
    TenantIdentifier: undefined,
    tenantName: undefined,
    ValuationApi: _valuationApi,
    useDomain: _useDomain,
    useDomainGetter: _useDomainGetter,
    useExecuteLiftAndSiftQuery: _useExecuteLiftAndSiftQuery,
    useFilters: _useFilters,
    useFormView: _useFormView,
    useGenerateReportMutation: _useGenerateReportMutation,
    useGridFieldsFromListView: _useGridFieldsFromListView,
    useHeaderContext: _useHeaderContext,
    useListView: _useListView,
    useReport: _useReport,
    useReportImage: _useReportImage,
    useReportValidation: _useReportValidation,
    useRouterBlockerForUnsavedChanges: _useRouterBlockerForUnsavedChanges,
});
export const ApplicationContextProvider = ({ AggregatorApi = _aggregatorApi, children, CurrentUser, DownloadFile = _DownloadFile, FolderApi = _folderApi, LiftSiftApi = _liftSiftApi, QueryAuthorizationApi = _queryAuthorizationApi, QueryClient = _queryClient, ReportingApi = _reportingApi, requestInit, ShowMessage = _ShowMessage, StrataApi = _strataApi, SystemInternalIngressPort = _systemInternalIngressPort, SystemMyReportDomainId = _systemMyReportDomainId, SystemRoleReportDomainId = _systemRoleReportDomainId, SystemMyReportsListViewId = _systemMyReportsListViewId, SystemRoleReportsListViewId = _systemRoleReportsListViewId, SystemOptionalPortfolioIdsExpression = _systemOptionalPortfolioIdsExpression, SystemPortfolioIdExpression = _systemPortfolioIdExpression, SystemPortfolioIdExpressionAlt = _systemPortfolioIdExpressionAlt, SystemPortfolioIdsExpression = _systemPortfolioIdsExpression, SystemReportDomainId = _systemReportDomainId, SystemReportPickerListViewId = _systemReportPickerListViewId, SystemSaveAsMyReportPanelFormId = _systemSaveAsMyReportPanelFormId, SystemSaveAsRoleReportPanelFormId = _systemSaveAsRoleReportPanelFormId, SystemScheduleMyReportFormId = _systemScheduleMyReportFormId, SystemScheduleRoleReportFormId = _systemScheduleRoleReportFormId, SystemStandardReportDomainId = _systemStandardReportDomainId, SystemSummaryDisplayName = _systemSummaryDisplayName, TaskApi = _taskApi, TenantIdentifier, tenantName, ValuationApi = _valuationApi, useDomain = _useDomain, useDomainGetter = _useDomainGetter, useExecuteLiftAndSiftQuery = _useExecuteLiftAndSiftQuery, useFilters = _useFilters, useFormView = _useFormView, useGenerateReportMutation = _useGenerateReportMutation, useGridFieldsFromListView = _useGridFieldsFromListView, useHeaderContext = _useHeaderContext, useListView = _useListView, useReport = _useReport, useReportImage = _useReportImage, useReportValidation = _useReportValidation, useRouterBlockerForUnsavedChanges = _useRouterBlockerForUnsavedChanges, }) => {
    const value = React.useMemo(() => ({
        AggregatorApi,
        CurrentUser,
        DownloadFile,
        FolderApi,
        LiftSiftApi,
        QueryAuthorizationApi,
        QueryClient,
        ReportingApi,
        requestInit,
        ShowMessage,
        StrataApi,
        SystemInternalIngressPort,
        SystemMyReportDomainId,
        SystemMyReportsListViewId,
        SystemRoleReportDomainId,
        SystemRoleReportsListViewId,
        SystemOptionalPortfolioIdsExpression,
        SystemPortfolioIdExpression,
        SystemPortfolioIdExpressionAlt,
        SystemPortfolioIdsExpression,
        SystemReportDomainId,
        SystemReportPickerListViewId,
        SystemSaveAsMyReportPanelFormId,
        SystemSaveAsRoleReportPanelFormId,
        SystemScheduleMyReportFormId,
        SystemScheduleRoleReportFormId,
        SystemStandardReportDomainId,
        SystemSummaryDisplayName,
        TaskApi,
        TenantIdentifier,
        tenantName,
        ValuationApi,
        useDomain,
        useDomainGetter,
        useExecuteLiftAndSiftQuery,
        useFilters,
        useFormView,
        useGenerateReportMutation,
        useGridFieldsFromListView,
        useHeaderContext,
        useListView,
        useReport,
        useReportImage,
        useReportValidation,
        useRouterBlockerForUnsavedChanges,
    }), [
        AggregatorApi,
        CurrentUser,
        DownloadFile,
        FolderApi,
        LiftSiftApi,
        QueryClient,
        QueryAuthorizationApi,
        ReportingApi,
        requestInit,
        ShowMessage,
        StrataApi,
        SystemInternalIngressPort,
        SystemMyReportDomainId,
        SystemMyReportsListViewId,
        SystemOptionalPortfolioIdsExpression,
        SystemPortfolioIdExpression,
        SystemPortfolioIdExpressionAlt,
        SystemPortfolioIdsExpression,
        SystemReportDomainId,
        SystemReportPickerListViewId,
        SystemSaveAsMyReportPanelFormId,
        SystemSaveAsRoleReportPanelFormId,
        SystemScheduleMyReportFormId,
        SystemScheduleRoleReportFormId,
        SystemStandardReportDomainId,
        SystemSummaryDisplayName,
        TaskApi,
        TenantIdentifier,
        tenantName,
        ValuationApi,
        useDomain,
        useDomainGetter,
        useExecuteLiftAndSiftQuery,
        useFilters,
        useFormView,
        useGenerateReportMutation,
        useGridFieldsFromListView,
        useHeaderContext,
        useListView,
        useReport,
        useReportImage,
        useReportValidation,
        useRouterBlockerForUnsavedChanges,
    ]);
    return (React.createElement(QueryClientProvider, { client: QueryClient },
        React.createElement(CoreApiContextProvider, { value: value },
            React.createElement(ApplicationContext.Provider, { value: value }, children))));
};
