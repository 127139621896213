import React from 'react';
import { PickerAndContentTemplate } from '../../templates/PickerAndContentTemplate/PickerAndContentTemplate';
import { ReportPicker } from '../../atoms/ReportPicker/ReportPicker';
import { ReportCatalogContent } from '../../molecules/ReportCatalogContent/ReportCatalogContent';
export const ReportCatalogPage = ({ portfolioId }) => {
    const [selectedReport, setSelectedReport] = React.useState();
    const [currencyExchange, setCurrencyExchange] = React.useState();
    const onChange = React.useCallback((data) => {
        if (selectedReport) {
            setCurrencyExchange({
                TargetCurrency: data.TargetCurrency,
                Source: data.Source,
                ReportPeriodStart: data.ReportPeriodStart,
                IncludesCurrencyExchange: data.IncludesCurrencyExchange,
            });
        }
    }, [selectedReport]);
    return (React.createElement(PickerAndContentTemplate, { content: React.createElement(ReportCatalogContent, { portfolioId: portfolioId, report: selectedReport, onChange: onChange, currencyExchange: currencyExchange }), picker: React.createElement(ReportPicker, { onReportSelectionChanged: (report) => {
                setCurrencyExchange({
                    TargetCurrency: report === null || report === void 0 ? void 0 : report.TargetCurrency,
                    Source: report === null || report === void 0 ? void 0 : report.Source,
                    ReportPeriodStart: report === null || report === void 0 ? void 0 : report.ReportPeriodStart,
                    IncludesCurrencyExchange: report === null || report === void 0 ? void 0 : report.IncludesCurrencyExchange,
                });
                setSelectedReport(report);
            }, portfolioId: portfolioId, report: selectedReport }) }));
};
export default ReportCatalogPage;
