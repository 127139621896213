import React from 'react';
import { styled } from 'styletron-react';
const StyledRoot = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    height: '100%',
}));
export const PageTemplate = ({ children }) => {
    return React.createElement(StyledRoot, null, children);
};
export default PageTemplate;
