export class UriBuilder {
    constructor(baseUri) {
        this.uri = baseUri;
    }
    build() {
        return this.uri;
    }
    withSort(order, orderBy) {
        if (!order || !orderBy)
            return this;
        const orderChar = order === 'desc' ? '-' : '+';
        this.uri = `${this.uri}?sort=${orderChar}${orderBy}`;
        return this;
    }
    debug() {
        console.log(this.uri);
    }
}
export default UriBuilder;
