import React from 'react';
import { Tab, TabBar, useDirtinessPrompt } from '@samc/react-ui-core';
import { TabTemplate } from '../../templates/TabTemplate/TabTemplate';
import { EditRoleReportForm as ReportInfoForm } from './EditRoleReportForm.SaveAsRoleReportForm';
import { ScheduleRoleReportForm } from '../../atoms/ScheduleRoleReportForm/ScheduleRoleReportForm';
var ETab;
(function (ETab) {
    ETab[ETab["ReportInfo"] = 0] = "ReportInfo";
    ETab[ETab["Schedule"] = 1] = "Schedule";
})(ETab || (ETab = {}));
export const EditRoleReportForm = ({ id, reportId }) => {
    const dirtinessScope = React.useRef(null);
    const { promptAndContinue } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    const [activeTab, setActiveTab] = React.useState(ETab.ReportInfo);
    const activateReportInfoTab = () => promptAndContinue(() => {
        setActiveTab(ETab.ReportInfo);
    });
    const activateScheduleTab = () => promptAndContinue(() => {
        setActiveTab(ETab.Schedule);
    });
    return (React.createElement(TabTemplate, { ref: dirtinessScope, tabBar: React.createElement(TabBar, { rounded: true, showBorder: true },
            React.createElement(Tab, { content: "Report Info", onClick: activateReportInfoTab, selected: activeTab === ETab.ReportInfo }),
            React.createElement(Tab, { content: "Schedule", onClick: activateScheduleTab, selected: activeTab === ETab.Schedule })), tabContent: React.createElement(React.Fragment, null,
            activeTab === ETab.ReportInfo && React.createElement(ReportInfoForm, { id: id, reportId: reportId }),
            activeTab === ETab.Schedule && React.createElement(ScheduleRoleReportForm, { id: id })) }));
};
export default EditRoleReportForm;
