import * as React from 'react';
import { ServiceDrivenViewSet, GridSelectionProvider, ActionOverrideContextProvider } from '@samc/screen-config-core';
import { HeaderContextProvider, RoutingWrapper } from '@samc/react-ui-history';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { FormView, ViewSetAction } from '@samc/screen-config-api';
import { RequestResult } from '@samc/react-ui-request';
import { EditingProvider } from '@samc/react-ui-grid';
import { CloneReport, ViewID } from '@samc/reports';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { overridenViewids } from './overrides';

interface Props {
  /**
   * The id of the StandardReport.
   */
  id: string;
  /**
   * Override of the useFormView hook called by ServiceDrivenViewSet to fetch the FormView.
   */
  useFormView: (formViewId: string) => RequestResult<FormView | undefined>;
}

export const StandardReportDetailPage = ({ id, useFormView }: Props): React.ReactElement => {
  const [triggeredAction, updateAction] = React.useState<ViewSetAction>();
  const [isLoading, setisLoading] = React.useState<boolean>(false);
  const { viewId } = triggeredAction ?? { viewId: '' };
  const { id: viewSetId, dId } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [viewsetKey, updateViewsetKey] = React.useState<number>(1);

  const handleSetPrimaryKey = React.useCallback(
    (value: string | undefined): void => {
      const params = new URLSearchParams(search);
      if (value) {
        params.set('primaryKeyValue', value);
        navigate(`${pathname}?${params.toString()}`);
      }
    },
    [pathname, search, navigate],
  );

  const refreshViewSet = React.useCallback(() => {
    return updateViewsetKey(viewsetKey + 1);
  }, [viewsetKey]);
  const spinnerControl = (): React.ReactElement => {
    if (isLoading) return <Spinner size={SpinnerSize.medium} />;
    return <></>;
  };

  return (
    <HeaderContextProvider>
      <EditingProvider>
        <ActionOverrideContextProvider
          value={overridenViewids.reduce(
            (o, v) => ({ ...o, [v]: (item: ViewSetAction): void => updateAction(item) }),
            {},
          )}
        >
          <GridSelectionProvider>
            <RoutingWrapper>
              <>
                {viewSetId && (
                  <ServiceDrivenViewSet
                    key={id}
                    viewSetId={viewSetId}
                    primaryKeyValue={id}
                    setPrimaryKeyValue={handleSetPrimaryKey}
                    useFormView={useFormView}
                    precedingControlRenderer={
                      viewId === ViewID.CloneReportToPortfolioReport || viewId === ViewID.CloneReport
                        ? spinnerControl
                        : undefined
                    }
                  />
                )}
                {triggeredAction &&
                  (viewId === ViewID.CloneReportToPortfolioReport || viewId === ViewID.CloneReport) && (
                    <CloneReport
                      refreshViewSet={refreshViewSet}
                      updateAction={updateAction}
                      setisLoading={setisLoading}
                      props={{ primaryKeyValue: id, domainId: dId ?? '' }}
                    />
                  )}
              </>
            </RoutingWrapper>
          </GridSelectionProvider>
        </ActionOverrideContextProvider>
      </EditingProvider>
    </HeaderContextProvider>
  );
};

export default StandardReportDetailPage;
