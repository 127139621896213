export class QueryExecuteRequestBuilder {
    constructor(domainId) {
        this.queryExecuteRequest = {
            domainId,
            primaryViewId: '-1',
            sorting: {},
            paging: { start: 0, stop: 50 },
            summaryMode: false,
        };
    }
    build() {
        return this.queryExecuteRequest;
    }
    withAdHocListViewMembers(adHocListViewMembers) {
        this.queryExecuteRequest.adhocListViewMembers = adHocListViewMembers;
        return this;
    }
    withFilterExpression(expression) {
        if (!expression)
            return this;
        if (!this.queryExecuteRequest.adhocFilter) {
            this.queryExecuteRequest.adhocFilter = {
                filterName: 'adhoc',
                advancedInd: true,
                expressionLang: 'Centric',
                advancedExpression: expression,
            };
            return this;
        }
        this.queryExecuteRequest.adhocFilter.advancedExpression += ` AND ${expression}`;
        return this;
    }
    withFilterIds(filterIds) {
        this.queryExecuteRequest.filterIds = filterIds;
        return this;
    }
    withPaging(start, stop) {
        this.queryExecuteRequest.paging = { start, stop };
        return this;
    }
    withPrimaryViewId(listViewId) {
        this.queryExecuteRequest.primaryViewId = listViewId;
        return this;
    }
    withSorting(order, orderBy) {
        if (!order || !orderBy)
            return this;
        this.queryExecuteRequest.sorting = {
            order,
            orderBy: { scalarExpression: `[${orderBy}]` },
        };
        return this;
    }
    withSummaryMode() {
        this.queryExecuteRequest.summaryMode = true;
        return this;
    }
    debug() {
        console.log(JSON.stringify(this.queryExecuteRequest, undefined, 2));
    }
}
export default QueryExecuteRequestBuilder;
