import React from 'react';
import { ExpressionBuilder } from '../../builders/ExpressionBuilder/ExpressionBuilder';
import { AddRoleReportPanel as BaseAddRoleReportPanel } from './AddRoleReportPanel.AddRoleReportForm';
export const AddRoleReportPanel = ({ filterExpression, filters, reportId, currencyExchange, }) => {
    const filtersAsExpression = filters === null || filters === void 0 ? void 0 : filters.map((filter) => {
        return filter.advancedExpression;
    }).join(' AND ');
    const combinedFilterExpression = new ExpressionBuilder(filterExpression).and(filtersAsExpression).build();
    const data = React.useMemo(() => ({
        Description: '',
        FilterExpression: combinedFilterExpression,
        IsActive: true,
        Name: '',
        ReportId: reportId,
        TargetCurrency: currencyExchange === null || currencyExchange === void 0 ? void 0 : currencyExchange.TargetCurrency,
        Source: currencyExchange === null || currencyExchange === void 0 ? void 0 : currencyExchange.Source,
        ReportPeriodStart: currencyExchange === null || currencyExchange === void 0 ? void 0 : currencyExchange.ReportPeriodStart,
        Report_IncludesCurrencyExchange: currencyExchange === null || currencyExchange === void 0 ? void 0 : currencyExchange.IncludesCurrencyExchange,
    }), [combinedFilterExpression, reportId, currencyExchange]);
    return React.createElement(BaseAddRoleReportPanel, { initialFormData: data, reportId: reportId });
};
export default AddRoleReportPanel;
