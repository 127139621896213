var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { ConvertGridFilterToFilterExpression, GetFilterModelForTextFilter, Grid, useAgGridApi, } from '@samc/react-ui-grid';
import { mapQueryResult } from '@samc/screen-config-core/lib/molecules/QueryExecuteManager/QueryExecuteManager';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { Spinner, SpinnerSize } from '@fluentui/react';
import useGridInjector from '@samc/screen-config-core/lib/hooks/useGridInjector/useGridInjector';
import { useExecuteLiftAndSiftQuery } from '../../hooks/useExecuteLiftAndSiftQuery/useExecuteLiftAndSiftQuery';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { QueryExecuteRequestBuilder } from '../../builders/QueryExecuteRequestBuilder/QueryExecuteRequestBuilder';
const noop = () => Promise.resolve();
export const ReportFilterGrid = ({ afterFetchData, canLoadGrid, filterIds, listView, onGridColumnFilterExpressionChange, onGridColumnSortChange, portfolioFilterExpression, primaryKey, renderControlBarItems, report, }) => {
    const { QueryClient, useGridFieldsFromListView } = useApplicationContext();
    const [executeQuery] = useExecuteLiftAndSiftQuery();
    const [gridApi, onGridReady] = useAgGridApi();
    const { frameworkComponents, isLoading, wrappedFields } = useGridFieldsFromListView({
        listView,
        useGridInjector,
    });
    React.useEffect(() => {
        if (canLoadGrid) {
            gridApi === null || gridApi === void 0 ? void 0 : gridApi.refreshServerSide({ purge: true });
        }
    }, [canLoadGrid, gridApi]);
    const fetchData = (params) => __awaiter(void 0, void 0, void 0, function* () {
        if (!wrappedFields)
            return;
        if (!canLoadGrid) {
            params.success({
                rowData: [],
                rowCount: 0,
            });
            return;
        }
        const filterModel = GetFilterModelForTextFilter(params.filterModel, wrappedFields);
        const adHocFilterExpression = Object.keys(params.filterModel).length > 0
            ? ConvertGridFilterToFilterExpression(filterModel, wrappedFields)
            : undefined;
        onGridColumnFilterExpressionChange === null || onGridColumnFilterExpressionChange === void 0 ? void 0 : onGridColumnFilterExpressionChange(adHocFilterExpression);
        onGridColumnSortChange === null || onGridColumnSortChange === void 0 ? void 0 : onGridColumnSortChange(params.order, params.orderBy);
        const result = yield QueryClient.fetchQuery([report.DomainId], () => executeQuery(new QueryExecuteRequestBuilder(report.DomainId)
            .withFilterExpression(portfolioFilterExpression)
            .withFilterExpression(adHocFilterExpression)
            .withFilterIds(filterIds)
            .withPaging(params.startRow, params.endRow)
            .withPrimaryViewId(report.ListViewId)
            .withSorting(params.order, params.orderBy)
            .build()), { retry: false });
        const mappedResult = mapQueryResult(result, primaryKey);
        params.success({
            rowData: mappedResult.Data,
            rowCount: mappedResult.TotalRecords,
        });
        afterFetchData === null || afterFetchData === void 0 ? void 0 : afterFetchData();
    });
    const modifiedWrappedFields = React.useMemo(() => wrappedFields === null || wrappedFields === void 0 ? void 0 : wrappedFields.map((field) => {
        if ('link' in field) {
            delete field.link;
        }
        return field;
    }), [wrappedFields]);
    if (isLoading) {
        return React.createElement(Spinner, { "data-testid": "spinner-ReportFilterGrid", size: SpinnerSize.large });
    }
    if (!wrappedFields) {
        return React.createElement(MessageBar, { text: "Error Resolving GridFieldConfiguration.", messageBarType: MessageBarType.error });
    }
    return (React.createElement(Grid, { key: report.Id, fields: modifiedWrappedFields, frameworkComponents: frameworkComponents, onBodyGridReady: onGridReady, onGridStateChange: fetchData, renderControlBarItems: renderControlBarItems, suppressDeleteButton: true, suppressEditToggle: true, suppressRowSelector: true, suppressExcelExport: true, onSubmit: noop }));
};
export default ReportFilterGrid;
