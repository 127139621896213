import { useQuery } from 'react-query';
import { useExecuteLiftAndSiftQuery } from '../useExecuteLiftAndSiftQuery/useExecuteLiftAndSiftQuery';
import { ReportsExecuteRequestBuilder } from '../../builders/ReportsExecuteRequestBuilder/ReportsExecuteRequestBuilder';
export const REPORTS_KEY = 'Reports';
export const useReport = (id) => {
    const [executeQuery] = useExecuteLiftAndSiftQuery();
    const requestBody = new ReportsExecuteRequestBuilder().withFilterById(id).build();
    const queryResult = useQuery([REPORTS_KEY, id], () => executeQuery(requestBody), {
        retry: false,
    });
    return queryResult;
};
export default useReport;
