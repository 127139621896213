import React from 'react';
import { useToken } from '@samc/single-spa-authentication';
import { styled } from 'styletron-react';
import { useApplicationContext } from '@samc/reports';

/* istanbul ignore next */
const StyledRoot = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  height: '100%',
}));

/* istanbul ignore next */
const StyledIframe = styled('iframe', () => ({
  height: '100%',
}));

export const HangfirePage = (): React.ReactElement => {
  const { SystemInternalIngressPort } = useApplicationContext();
  const token = useToken();

  React.useEffect(() => {
    document.cookie = `Hangfire=${token}; path=/reporting-hangfire; SameSite=Strict; Secure`;
  }, [token]);

  return (
    <StyledRoot>
      <StyledIframe
        title="reporting-hangfire"
        src={`${window.location.origin}:${SystemInternalIngressPort}/reporting-hangfire/hangfire`}
      />
    </StyledRoot>
  );
};

export default HangfirePage;
