import React from 'react';
import { styled } from 'styletron-react';
import { PageHeader } from '../../atoms/PageHeader/PageHeader';
const StyledFlexColumn = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
}));
const StyledTitle = styled('div', () => ({
    fontSize: '17pt',
    fontFamily: 'var(--react-ui-library-primary-font)',
}));
export const ReportCatalogPageHeader = ({ children }) => {
    return (React.createElement(PageHeader, null,
        React.createElement(React.Fragment, null,
            React.createElement(StyledFlexColumn, null,
                React.createElement(StyledTitle, null, "Report Catalog")),
            children)));
};
export default ReportCatalogPageHeader;
