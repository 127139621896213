var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fetch as _Fetch } from '../Fetch/Fetch';
export const GetFiltersByIdAsync = (api_1, ids_1, requestInit_1, ...args_1) => __awaiter(void 0, [api_1, ids_1, requestInit_1, ...args_1], void 0, function* (api, ids, requestInit, fetch = _Fetch) {
    return Promise.all(ids.map((id) => __awaiter(void 0, void 0, void 0, function* () {
        const uri = `${api}/api/v1/Filter/${id}`;
        const response = yield fetch(uri, {
            method: 'GET',
            headers: requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers,
        });
        return response.json();
    })));
});
export default GetFiltersByIdAsync;
