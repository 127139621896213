import React from 'react';
import { toastError, toastInfo, ToastMessage, toastSuccess, toastWarning } from '@samc/react-ui-core';
export const ShowMessage = (msg, messageType) => {
    switch (messageType) {
        case 'error':
            toastError(React.createElement(ToastMessage, { title: "Error", message: msg }));
            break;
        case 'success':
            toastSuccess(React.createElement(ToastMessage, { title: "Success", message: msg }));
            break;
        case 'warning':
            toastWarning(React.createElement(ToastMessage, { title: "Warning", message: msg }));
            break;
        case 'info':
            toastInfo(React.createElement(ToastMessage, { title: "Info", message: msg }));
            break;
        default:
            toastInfo(React.createElement(ToastMessage, { title: "API Message", message: msg }));
    }
};
export default ShowMessage;
