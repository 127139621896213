import React from 'react';
import { styled } from 'styletron-react';
const StyledRoot = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: '1rem',
}));
const StyledTabBarWrapper = styled('div', () => ({
    flex: '0 0 auto',
}));
const StyledTabContentWrapper = styled('div', () => ({
    flex: '1 1 auto',
}));
const _TabTemplate = ({ tabBar, tabContent }, ref) => {
    return (React.createElement(StyledRoot, { ref: ref },
        React.createElement(StyledTabBarWrapper, null, tabBar),
        React.createElement(StyledTabContentWrapper, null, tabContent)));
};
export const TabTemplate = React.forwardRef(_TabTemplate);
export default TabTemplate;
