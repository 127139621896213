import React from 'react';
import { styled } from 'styletron-react';
import { ReportExport as BaseReportExport } from './ReportExport.Export';
import { ReportValidation } from '../ReportValidation/ReportValidation';
const StyledFlexRow = styled('div', () => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
}));
export const ReportExport = ({ domainId, filterSelection, gridColumnFilterExpression, onExportReport, portfolioFilterExpression, portfolioId, reportId, validationExpression, validationText, currencyExchange, }) => {
    return (React.createElement(StyledFlexRow, null,
        React.createElement(ReportValidation, { domainId: domainId, filterSelection: filterSelection, gridColumnFilterExpression: gridColumnFilterExpression, portfolioFilterExpression: portfolioFilterExpression, portfolioId: portfolioId, validationExpression: validationExpression, validationText: validationText }, (canExportReport) => (React.createElement(BaseReportExport, { filterSelection: filterSelection, gridColumnFilterExpression: gridColumnFilterExpression, isExportDisabled: !canExportReport, onExportReport: onExportReport, reportId: reportId, currencyExchange: currencyExchange })))));
};
export default ReportExport;
