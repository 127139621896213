import React from 'react';
import { SectionHeader } from '@samc/react-ui-form';
import { Button } from '@samc/react-ui-core';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { styled } from 'styletron-react';
import { HorizontalRule } from '../HorizontalRule/HorizontalRule';
import { ReportImageModal } from '../ReportImageModal/ReportImageModal';
const StyledMarginedDiv = styled('div', () => ({
    marginBottom: '1rem',
}));
const formViewId = 'REPORT_ReportCatalogExportFormview';
export const ReportInfo = ({ report, onChange }) => {
    const [isModalOpen, setModalOpen] = React.useState(false);
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
    return (React.createElement("div", null,
        React.createElement("div", { style: {
                float: 'left',
                width: report.IncludesCurrencyExchange !== undefined && report.IncludesCurrencyExchange === true ? '48%' : '100%',
            } },
            React.createElement(SectionHeader, { title: "Report Info" }),
            React.createElement(HorizontalRule, null),
            React.createElement(StyledMarginedDiv, null, report.Description),
            report.ReportImageStrataId && React.createElement(Button, { buttonType: "primary", text: "View Style", onClick: openModal }),
            React.createElement(ReportImageModal, { isOpen: isModalOpen, onClose: closeModal, strataImageId: report.ReportImageStrataId })),
        report && report.IncludesCurrencyExchange !== undefined && report.IncludesCurrencyExchange === true && (React.createElement("div", { style: { float: 'left', width: '49%', margin: '0 0 0 1%' } },
            React.createElement(SectionHeader, { title: "CURRENCY EXCHANGE: TARGET CURRENCY & START PERIOD" }),
            React.createElement(HorizontalRule, null),
            React.createElement("div", null,
                React.createElement(FormScreenWrapper, { formViewId: formViewId, primaryKeyValue: report.Id, onChange: onChange, style: { margin: '-55px 0 0 -18px' }, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }))))));
};
export default ReportInfo;
