import React from 'react';
import { useStandardReport } from '@samc/reports';
import { Spinner } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { StandardReportDetailPage as BaseStandardReportDetailPage } from './StandardReportDetailPage.UseFormView';

interface Props {
  domainId: string;
  /**
   * The id of the StandardReport.
   */
  id: string;
}

export const StandardReportDetailPage = ({ domainId, id }: Props): React.ReactElement => {
  const standardReportResponse = useStandardReport(id, domainId, [], '');

  if (standardReportResponse.isLoading) {
    return <Spinner />;
  }

  if (standardReportResponse.isError || !standardReportResponse.data?.Data?.[0]) {
    return <MessageBar text="Error fetching Standard Report." messageBarType={MessageBarType.error} />;
  }

  return <BaseStandardReportDetailPage id={id} standardReport={standardReportResponse.data.Data[0]} />;
};

export default StandardReportDetailPage;
