import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ViewSetPage } from '../../pages/ViewSetPage/ViewSetPage';
import { ReportCatalogPage } from '../../pages/ReportCatalogPage/ReportCatalogPage';
import { usePortfolioId } from '../../hooks/usePortfolioId/usePortfolioId';
import { ETab } from '../../pages/ReportCatalogPage/ReportCatalogPage.Tabs';
import { StandardReportDetailPage } from '../../pages/StandardReportDetailPage/StandardReportDetailPage';
import { HangfirePage } from '../../pages/HangfirePage/HangfirePage';

export const Router = (): React.ReactElement => {
  const portfolioId = usePortfolioId();

  return (
    <Routes>
      <Route path="/StandardReportDetails/:dId/:id" element={<StandardReportDetailPage />} />
      <Route path="/:id/:tabId/:subtabId" element={<ViewSetPage />} />
      <Route path="/:id/:tabId" element={<ViewSetPage />} />
      <Route path="/:id" element={<ViewSetPage />} />
      <Route
        path="/report-catalog"
        element={<ReportCatalogPage key={portfolioId} portfolioId={portfolioId} activeTab={ETab.ReportCatalog} />}
      />
      <Route
        path="/report-catalog/my-reports"
        element={<ReportCatalogPage key={portfolioId} portfolioId={portfolioId} activeTab={ETab.MyReports} />}
      />
      <Route
        path="/report-catalog/role-reports"
        element={<ReportCatalogPage key={portfolioId} portfolioId={portfolioId} activeTab={ETab.RoleReports} />}
      />
      <Route path="/reporting-hangfire" element={<HangfirePage />} />
    </Routes>
  );
};

export default Router;
