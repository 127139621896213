var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fetch as _Fetch } from '../Fetch/Fetch';
export const GetReportImageByIdAsync = (api_1, TenantIdentifier_1, strataImageId_1, requestInit_1, ...args_1) => __awaiter(void 0, [api_1, TenantIdentifier_1, strataImageId_1, requestInit_1, ...args_1], void 0, function* (api, TenantIdentifier, strataImageId, requestInit, fetch = _Fetch) {
    if (!strataImageId || !TenantIdentifier)
        return undefined;
    const url = `${api}/api/${TenantIdentifier}/manager/v1/files/${strataImageId}/_download`;
    const response = yield fetch(url, {
        method: 'POST',
        headers: requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers,
    });
    return response.json();
});
export default GetReportImageByIdAsync;
