import React from 'react';
import { Image, ImageLoadState, Shimmer, ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
export const ReportImage = ({ alt, error, src }) => {
    const [imageLoadingState, setImageLoadingState] = React.useState(ImageLoadState.notLoaded);
    const onLoadingStateChange = (loadState) => {
        setImageLoadingState(loadState);
    };
    const isLoading = !src || imageLoadingState === ImageLoadState.notLoaded;
    const isErrored = error || imageLoadingState === ImageLoadState.error;
    return (React.createElement(React.Fragment, null,
        isLoading && (React.createElement(Shimmer, { role: "progressbar", customElementsGroup: React.createElement(ShimmerElementsGroup, { shimmerElements: [{ type: ShimmerElementType.line, height: 250, width: 500 }] }) })),
        isErrored && React.createElement(MessageBar, { text: "Error Downloading Image.", messageBarType: MessageBarType.error }),
        React.createElement(Image, { src: src, alt: alt, onLoadingStateChange: onLoadingStateChange })));
};
export default ReportImage;
