import { QueryExecuteRequestBuilder } from '../QueryExecuteRequestBuilder/QueryExecuteRequestBuilder';
export class MyReportsExecuteRequestBuilder {
    constructor(domainId) {
        this.queryExecuteRequestBuilder = new QueryExecuteRequestBuilder(domainId).withAdHocListViewMembers([
            {
                scalarDisplayName: 'Name',
                scalarExpression: '[Name]',
            },
            {
                scalarDisplayName: 'Description',
                scalarExpression: '[Description]',
            },
            {
                scalarDisplayName: 'DataLevel',
                scalarExpression: '[DataLevel]',
            },
            {
                scalarDisplayName: 'ReportId',
                scalarExpression: '[ReportId]',
            },
            {
                scalarDisplayName: 'FilterExpression',
                scalarExpression: '[FilterExpression]',
            },
            {
                scalarDisplayName: 'TargetCurrency',
                scalarExpression: '[TargetCurrency]',
            },
            {
                scalarDisplayName: 'ReportPeriodStart',
                scalarExpression: '[ReportPeriodStart]',
            },
            {
                scalarDisplayName: 'Source',
                scalarExpression: '[Source]',
            },
        ]);
    }
    withFilterExpression(expression) {
        this.queryExecuteRequestBuilder.withFilterExpression(expression);
        return this;
    }
    withFilterById(id) {
        this.queryExecuteRequestBuilder.withFilterExpression(`[Id] = '${id}'`);
        return this;
    }
    withFilterByIsActive() {
        this.queryExecuteRequestBuilder.withFilterExpression('[IsActive] == true');
        return this;
    }
    withFilterByPortfolioId(portfolioId) {
        this.queryExecuteRequestBuilder.withFilterExpression(`ISNULLOREMPTY([Optional_Portfolio_Ids]) OR DOESCONTAIN('${portfolioId}', [Optional_Portfolio_Ids])`);
        return this;
    }
    withPaging(start, stop) {
        this.queryExecuteRequestBuilder.withPaging(start, stop);
        return this;
    }
    withSorting(order, orderBy) {
        this.queryExecuteRequestBuilder.withSorting(order, orderBy);
        return this;
    }
    build() {
        return this.queryExecuteRequestBuilder.build();
    }
    debug() {
        console.log(JSON.stringify(this.queryExecuteRequestBuilder, undefined, 2));
    }
}
export default MyReportsExecuteRequestBuilder;
