import React from 'react';
import { styled } from 'styletron-react';
import { ReportInfo } from '../../atoms/ReportInfo/ReportInfo';
import { ReportFilter } from '../../atoms/ReportFilter/ReportFilter';
import { ReportFilterGrid } from '../../atoms/ReportFilterGrid/ReportFilterGrid';
const StyledRoot = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    flex: '1 1 auto',
}));
export const ReportCatalogContent = ({ portfolioFilterExpression, portfolioId, report, onChange, currencyExchange, }) => {
    const [gridColumnFilterExpression, setGridColumnFilterExpression] = React.useState();
    const initialFilterIds = report.FilterId ? [report.FilterId] : [];
    const [filterSelection, setFilterSelection] = React.useState(initialFilterIds);
    const [sortOrder, setSortOrder] = React.useState();
    const [sortOrderBy, setSortOrderBy] = React.useState();
    const updateSortOrderState = (order, orderBy) => {
        setSortOrder(order);
        setSortOrderBy(orderBy);
    };
    return (React.createElement(StyledRoot, null,
        React.createElement(ReportInfo, { report: report, onChange: onChange }),
        React.createElement(ReportFilter, { filterSelection: filterSelection, gridColumnFilterExpression: gridColumnFilterExpression, portfolioFilterExpression: portfolioFilterExpression, portfolioId: portfolioId, report: report, sortOrder: sortOrder, sortOrderBy: sortOrderBy, currencyExchange: currencyExchange }),
        React.createElement(ReportFilterGrid, { filterSelection: filterSelection, onFilterSelectionChanged: setFilterSelection, onGridColumnFilterExpressionChange: setGridColumnFilterExpression, onGridColumnSortChange: updateSortOrderState, portfolioFilterExpression: portfolioFilterExpression, report: report })));
};
export default ReportCatalogContent;
