import { useQuery } from 'react-query';
import { GetFiltersByIdAsync } from '../../functions/GetFiltersByIdAsync/GetFiltersByIdAsync';
import { useApplicationContext } from '../useApplicationContext/useApplicationContext';
export const FILTERS_KEY = 'Filters';
export const useFilters = (ids) => {
    const { AggregatorApi, requestInit } = useApplicationContext();
    const queryResult = useQuery([FILTERS_KEY, ...ids], () => GetFiltersByIdAsync(AggregatorApi, ids, requestInit), {
        retry: false,
    });
    return queryResult;
};
export default useFilters;
