var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export function createGuid() {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export const DefaultValidationResponse = {
    messages: [],
    list: [],
    showList: true,
    request: {
        domainId: '',
        requestIdentifier: '',
        payload: {},
    },
    prompts: [],
    childResponses: {},
    statusCode: 0,
    messageCount: 0,
    newIds: {},
    updatedIds: [],
    deletedIds: [],
    requestIdentifier: '',
    domainId: '',
};
export const getMessageWithRowIndexed = (index, message) => message.toLowerCase().indexOf('row') < 0 ? `Row ${index} - ${message}` : message;
export const getCustomizedErrors = (response, { payload }) => {
    let msgs = response.messages;
    if (response.messages.length) {
        msgs = response.messages.map((_a) => {
            var { recordIdentifier, title } = _a, err = __rest(_a, ["recordIdentifier", "title"]);
            return (Object.assign(Object.assign({}, err), { title: recordIdentifier && payload[recordIdentifier] && !Number.isNaN(Number(payload[recordIdentifier].__INDEX__))
                    ? getMessageWithRowIndexed(Number(payload[recordIdentifier].__INDEX__), title)
                    : title, recordIdentifier,
                response }));
        });
    }
    return msgs;
};
export const updateChildResponse = (request, response) => {
    if (Object.values(response.childResponses).length) {
        Object.keys(response.childResponses).forEach((ri) => {
            if (response.childResponses[ri].messages.length) {
                Object.values(request.payload).forEach((p) => {
                    var _a;
                    (_a = p.__CHILD_REQUESTS__) === null || _a === void 0 ? void 0 : _a.forEach((childrequest) => {
                        if (childrequest.requestIdentifier === ri) {
                            Object.keys(childrequest.payload).forEach((crp, i) => {
                                response.childResponses[ri].messages = response.childResponses[ri].messages.map((msg) => (Object.assign(Object.assign({}, msg), { title: msg.recordIdentifier === crp ? getMessageWithRowIndexed(1 + i, msg.title) : msg.title })));
                            });
                        }
                    });
                });
            }
        });
    }
    return Object.assign({}, response);
};
export const getValidationResponse = (request, response) => (Object.assign(Object.assign({}, updateChildResponse(request, response)), { list: getCustomizedErrors(response, request), messages: getCustomizedErrors(response, request), showList: true, request }));
