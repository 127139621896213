var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { mergeHeaders } from '@samc/react-ui-request';
import { Fetch } from '../Fetch/Fetch';
export const ExecuteLiftAndSiftQuery = (liftSiftApi, requestBody, requestInit, showError) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield Fetch(`${liftSiftApi}/api/v2/Query/Execute`, {
            method: 'POST',
            headers: mergeHeaders(requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, {
                'Content-Type': 'application/json; charset=utf-8',
            }),
            body: JSON.stringify(requestBody),
        });
        return response.json();
    }
    catch (e) {
        if (e instanceof Error) {
            showError === null || showError === void 0 ? void 0 : showError(e.message);
        }
        throw e;
    }
});
export default ExecuteLiftAndSiftQuery;
