import { useQuery } from 'react-query';
import { useApplicationContext } from '../useApplicationContext/useApplicationContext';
import { GetReportImageByIdAsync } from '../../functions/GetReportImageByIdAsync/GetReportImageByIdAsync';
export const REPORT_IMAGE_KEY = 'ReportImage';
export const useReportImage = (strataImageId) => {
    const { requestInit, StrataApi, TenantIdentifier } = useApplicationContext();
    const queryResult = useQuery([REPORT_IMAGE_KEY, strataImageId], () => GetReportImageByIdAsync(StrataApi, TenantIdentifier, strataImageId, requestInit), { retry: false });
    return queryResult;
};
export default useReportImage;
