var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { SitusHeader, ControlBar, useDirtinessPrompt } from '@samc/react-ui-core';
import { useHeaderContext } from '@samc/react-ui-history';
export const PanelHeader = (props) => {
    const { title, cancelDisabledOverride, dirtinessScope, onCancel, onSubmitAndClose, submitDisabledOverride } = props;
    const { controlBarProps } = useHeaderContext();
    const { promptAndContinue, save, isDirty } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    const handleResetForm = () => promptAndContinue(() => {
        onCancel === null || onCancel === void 0 ? void 0 : onCancel();
    });
    const handleSubmitAndClose = () => __awaiter(void 0, void 0, void 0, function* () {
        yield save();
        onSubmitAndClose === null || onSubmitAndClose === void 0 ? void 0 : onSubmitAndClose();
    });
    return (React.createElement(SitusHeader, { title: title },
        React.createElement(ControlBar, { cancel: handleResetForm, submitAndClose: handleSubmitAndClose, submitDisabled: submitDisabledOverride !== null && submitDisabledOverride !== void 0 ? submitDisabledOverride : ((controlBarProps === null || controlBarProps === void 0 ? void 0 : controlBarProps.submitDisabled) || !isDirty), cancelDisabled: cancelDisabledOverride !== null && cancelDisabledOverride !== void 0 ? cancelDisabledOverride : controlBarProps === null || controlBarProps === void 0 ? void 0 : controlBarProps.cancelDisabled })));
};
export default PanelHeader;
