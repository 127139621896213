import React from 'react';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { SaveAsRoleReportForm as BaseSaveAsRoleReportForm } from './SaveAsRoleReportForm.FormScreen';
import { useExpressionField } from '../../hooks/useExpressionField/useExpressionField';
export const SaveAsRoleReportForm = ({ domainId, id, initialFormData }) => {
    const { SystemSaveAsRoleReportPanelFormId, useFormView } = useApplicationContext();
    const formViewRequest = useFormView(SystemSaveAsRoleReportPanelFormId);
    const [expressionFieldRenderer] = useExpressionField(domainId);
    const useFormViewOverride = (_formViewId) => {
        const { data } = formViewRequest;
        if (data) {
            data.formViewMembers.FilterExpression.customRenderer = expressionFieldRenderer;
        }
        return {
            isError: formViewRequest.isError,
            isLoading: formViewRequest.isLoading,
            data: formViewRequest.data,
            refresh: formViewRequest.refresh,
            error: formViewRequest.error,
        };
    };
    return React.createElement(BaseSaveAsRoleReportForm, { id: id, initialFormData: initialFormData, useFormView: useFormViewOverride });
};
export default SaveAsRoleReportForm;
