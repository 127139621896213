import React from 'react';
import { useFormView as useFormViewScreenConfig, FormView } from '@samc/screen-config-api';
import { RequestResult } from '@samc/react-ui-request';
import { useExpressionField } from '@samc/reports';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getFolderDropdownFormFieldRenderer } from '@samc/folder-ui-core';
import { StandardReportDetailPage as BaseStandardReportDetailPage } from './StandardReportDetailPage.ViewSet';

interface Props {
  /**
   * The id of the StandardReport.
   */
  id: string;
  standardReport: Record<string, unknown>;
}

export const StandardReportDetailPage = ({ id, standardReport }: Props): React.ReactElement => {
  const standardReportDomainId = String(standardReport.DomainId);

  const [expressionFieldRenderer] = useExpressionField(standardReportDomainId, true);

  const useFormView = (formViewId: string): RequestResult<FormView | undefined> => {
    const formViewResponse = useFormViewScreenConfig(formViewId);
    const { data } = formViewResponse;

    if (data?.formViewMembers.ListViewId) {
      const listViewId = data?.formViewMembers.ListViewId;
      listViewId.onRenderField = getFolderDropdownFormFieldRenderer({
        spaceName: standardReportDomainId || '',
        itemType: 'DomainView',
      });
      data.formViewMembers.ListViewId = listViewId;
    }

    if (data?.formViewMembers.FilterId) {
      const filterId = data?.formViewMembers.FilterId;
      filterId.onRenderField = getFolderDropdownFormFieldRenderer({
        spaceName: standardReportDomainId,
        itemType: 'DomainFilter',
      });
      data.formViewMembers.FilterId = filterId;
    }

    if (
      data?.formViewMembers.ValidationExpression &&
      data?.formViewMembers.ValidationExpression.editableExpression === 'true'
    ) {
      data.formViewMembers.ValidationExpression.onRenderField = expressionFieldRenderer;
    }

    return {
      isError: formViewResponse.isError,
      isLoading: formViewResponse.isLoading,
      data: formViewResponse.data,
      refresh: formViewResponse.refresh,
      error: formViewResponse.error,
    };
  };

  return <BaseStandardReportDetailPage id={id} useFormView={useFormView} />;
};

export default StandardReportDetailPage;
