var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from 'react-query';
import { useApplicationContext } from '../useApplicationContext/useApplicationContext';
import { GenerateReport } from '../../functions/GenerateReport/GenerateReport';
export const useGenerateReportMutation = () => {
    const { DownloadFile, ReportingApi, requestInit, ShowMessage } = useApplicationContext();
    const generateReport = (_a) => __awaiter(void 0, [_a], void 0, function* ({ domainId, filterExpression, filterIds = [], sortOrder, sortOrderBy, reportId, templateId, viewId, source, reportPeriodStart, targetCurrency, includesCurrencyExchange, }) {
        return GenerateReport(ReportingApi, domainId, viewId, reportId, source, reportPeriodStart, targetCurrency, includesCurrencyExchange, filterExpression, requestInit, templateId, sortOrder, sortOrderBy, filterIds);
    });
    const mutation = useMutation(generateReport, {
        onSuccess: (response) => __awaiter(void 0, void 0, void 0, function* () {
            if (response.status === 200) {
                const contentDisposition = response.headers.get('content-disposition');
                const fileNameRegex = /filename=(.+);/;
                const fileNameMatch = contentDisposition === null || contentDisposition === void 0 ? void 0 : contentDisposition.match(fileNameRegex);
                DownloadFile(yield response.blob(), fileNameMatch === null || fileNameMatch === void 0 ? void 0 : fileNameMatch[1]);
            }
            else {
                ShowMessage('Failed to export Report.', 'error');
            }
        }),
        onError: (e) => {
            const error = e;
            if (error.message.includes('timed out')) {
                ShowMessage('This report is taking too long to generate. Please create a scheduled report instead.', 'error');
            }
            else {
                ShowMessage('Failed to export Report.', 'error');
            }
        },
    });
    return [mutation];
};
export default useGenerateReportMutation;
