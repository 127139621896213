import React from 'react';
import { Spinner } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { SaveAsRoleReportForm as BaseSaveAsRoleReportForm } from './SaveAsRoleReportForm.UseFormView';
export const SaveAsRoleReportForm = ({ id, initialFormData, reportId }) => {
    const { useReport } = useApplicationContext();
    const reportRequest = useReport(reportId);
    if (reportRequest.isLoading) {
        return React.createElement(Spinner, { "data-testid": "spinner-SaveAsRoleReportPanel" });
    }
    if (reportRequest.isError || !reportRequest.data) {
        return React.createElement(MessageBar, { text: "Error fetching Report.", messageBarType: MessageBarType.error });
    }
    const report = reportRequest.data.Data[0];
    return React.createElement(BaseSaveAsRoleReportForm, { domainId: report.DomainId, id: id, initialFormData: initialFormData });
};
export default SaveAsRoleReportForm;
