import React from 'react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { useLocation, useParams } from 'react-router-dom';
import { StandardReportDetailPage as BaseStandardReportDetailPage } from './StandardReportDetailPage.UseStandardReport';

export const StandardReportDetailPage = (): React.ReactElement => {
  const { search } = useLocation();
  const { dId } = useParams();
  const params = new URLSearchParams(search);
  const primaryKeyValue = params.get('primaryKeyValue');

  if (!primaryKeyValue) {
    return <MessageBar text='Error resolving "primaryKeyValue" from URL' messageBarType={MessageBarType.error} />;
  }

  if (!dId) {
    return <MessageBar text='Error resolving "domainId" from URL' messageBarType={MessageBarType.error} />;
  }

  return <BaseStandardReportDetailPage domainId={dId} id={primaryKeyValue} />;
};

export default StandardReportDetailPage;
