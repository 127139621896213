import React from 'react';
import { ControlBar, useDirtinessPrompt } from '@samc/react-ui-core';
import { ReportCatalogPageHeader as BaseReportCatalogPageHeader } from './ReportCatalogPageHeader.UnsavedChanges';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
export const ReportCatalogPageHeader = ({ showControlBar, dirtinessScope, }) => {
    var _a;
    const { useHeaderContext } = useApplicationContext();
    const { promptAndContinue, save, isDirty } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    const { controlBarProps } = useHeaderContext();
    const onCancel = () => promptAndContinue();
    const onSave = () => save().then();
    if (!showControlBar || !controlBarProps) {
        return React.createElement(BaseReportCatalogPageHeader, null, null);
    }
    return (React.createElement(BaseReportCatalogPageHeader, null,
        React.createElement(ControlBar, { cancel: onCancel, submit: onSave, submitDisabled: (_a = controlBarProps.submitDisabled) !== null && _a !== void 0 ? _a : !isDirty, cancelDisabled: controlBarProps.cancelDisabled })));
};
export default ReportCatalogPageHeader;
