import React from 'react';
import { styled } from 'styletron-react';
import { Spinner } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
const StyledFlexItem = styled('div', () => ({
    flex: '1 1 auto',
}));
const StyledMessageBar = styled(MessageBar, () => ({
    marginBottom: 'unset',
}));
export const ReportValidation = ({ children, domainId, filterSelection, gridColumnFilterExpression, portfolioFilterExpression, portfolioId, validationExpression, validationText, }) => {
    var _a;
    const { SystemSummaryDisplayName, useReportValidation } = useApplicationContext();
    const reportValidationQueryResult = useReportValidation(domainId, filterSelection, portfolioId, validationExpression, gridColumnFilterExpression, portfolioFilterExpression);
    const validationResult = !!((_a = reportValidationQueryResult.data) === null || _a === void 0 ? void 0 : _a.Data[0][SystemSummaryDisplayName]);
    const canExportReport = !validationExpression || validationResult;
    if (reportValidationQueryResult.isLoading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(StyledFlexItem, null),
            React.createElement(Spinner, { "data-testid": "spinner-ReportValidation" }),
            children(false)));
    }
    if (reportValidationQueryResult.isError) {
        return (React.createElement(React.Fragment, null,
            React.createElement(StyledFlexItem, null,
                React.createElement(StyledMessageBar, { text: "Failed to evaluate this reports validation expression.", messageBarType: MessageBarType.error })),
            children(false)));
    }
    if (!canExportReport) {
        return (React.createElement(React.Fragment, null,
            React.createElement(StyledFlexItem, null,
                React.createElement(StyledMessageBar, { text: validationText, messageBarType: MessageBarType.error })),
            children(false)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledFlexItem, null),
        children(true)));
};
export default ReportValidation;
