import { useRequestBase } from '@samc/react-ui-request';
import { useQBQuery } from '@samc/screen-config-api';
import { ApplicationContext } from '../../contexts/ApplicationContext/ApplicationContext';
export const REPORT_KEY = 'Reports';
export const useStandardReport = (Id, DomainId, adhocListViewMembers, keyChange) => {
    const qbQuery = useQBQuery();
    const requestBody = {
        domainId: DomainId,
        primaryViewId: '-1',
        adhocListViewMembers: [
            ...[
                {
                    scalarDisplayName: 'Id',
                    scalarExpression: '[Id]',
                },
                {
                    scalarDisplayName: 'Name',
                    scalarExpression: '[Name]',
                },
                {
                    scalarDisplayName: 'DomainId',
                    scalarExpression: '[DomainId]',
                },
            ],
            ...adhocListViewMembers,
        ],
        sorting: {},
        paging: { start: 0, stop: 1 },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[Id] = '${Id}'`,
        },
    };
    const result = useRequestBase({
        key: REPORT_KEY + keyChange + Id + DomainId,
        query: qbQuery,
        params: [requestBody],
        requestContext: ApplicationContext,
    });
    return result;
};
export default useStandardReport;
