import React from 'react';
import { DomainContextProvider } from '@samc/vmsnext-querybuilder';
import { FilterControlBarItem } from '@samc/screen-config-core/lib/molecules/QueryBuilderControlBarItems/FilterControlBarItem/FilterControlBarItem';
export const GridFilter = ({ filterSelection, onFilterSelectionChanged, sequentialDomainId, }) => {
    const domainContext = React.useContext(DomainContextProvider);
    React.useEffect(() => {
        domainContext.fetchDomain(sequentialDomainId, undefined, undefined, undefined);
    }, [domainContext, sequentialDomainId]);
    const setSelectedFilterIds = (selectedFilterIds) => {
        onFilterSelectionChanged === null || onFilterSelectionChanged === void 0 ? void 0 : onFilterSelectionChanged(selectedFilterIds);
    };
    return (React.createElement(FilterControlBarItem, { enableDrawer: true, selectedFilterIds: filterSelection, setSelectedFilterIds: setSelectedFilterIds }));
};
export default GridFilter;
