import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePortfolioId = (): string | null => {
  const [params] = useSearchParams();

  const [portfolioId, setPortfolioId] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (params.has('portfolioId')) {
      setPortfolioId(params.get('portfolioId'));
    }

    if (params.has('PortfolioId')) {
      setPortfolioId(params.get('PortfolioId'));
    }
  }, [params]);

  return portfolioId;
};

export default usePortfolioId;
