import React from 'react';
import { QueryBuilderContextProvider } from '@samc/screen-config-core/lib/organisms/ConfigureDomain/QuerybuilderContextProvider/QuerybuilderContextProvider';
import { Spinner } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { GridFilter as BaseGridFilter } from './GridFilter.FilterControlBar';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
export const GridFilter = ({ domainId, filterSelection, onFilterSelectionChanged }) => {
    var _a;
    const { useDomain } = useApplicationContext();
    const domainRequest = useDomain(domainId);
    if (domainRequest.isLoading) {
        return React.createElement(Spinner, null);
    }
    if (domainRequest.isError || !((_a = domainRequest.data) === null || _a === void 0 ? void 0 : _a.sequentialId)) {
        return React.createElement(MessageBar, { text: "Error fetching domain.", messageBarType: MessageBarType.error });
    }
    return (React.createElement(QueryBuilderContextProvider, { domainId: domainRequest.data.sequentialId },
        React.createElement(BaseGridFilter, { filterSelection: filterSelection, onFilterSelectionChanged: onFilterSelectionChanged, sequentialDomainId: domainRequest.data.sequentialId })));
};
export default GridFilter;
