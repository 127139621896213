import React from 'react';
import { MessageBar } from '@samc/react-ui-core';
import { ReportCatalogContent as BaseReportCatalogContent } from './ReportCatalogContent.UseDomain';
export const ReportCatalogContent = ({ portfolioId, report, onChange, currencyExchange, }) => {
    if (!report) {
        return React.createElement(MessageBar, { text: "Select a Report from the dropdown..." });
    }
    return (React.createElement(BaseReportCatalogContent, { portfolioId: portfolioId, report: report, onChange: onChange, currencyExchange: currencyExchange }));
};
export default ReportCatalogContent;
