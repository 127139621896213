import { getValidExpressions } from '@samc/screen-config-core/lib/utils/ExpressionValidation/ExpressionValidationUtilities';
export class ExpressionBuilder {
    constructor(expression) {
        this.expressions = [];
        this.and(expression);
    }
    and(expression) {
        if (!expression)
            return this;
        this.expressions = [...this.expressions, expression];
        return this;
    }
    build(domain) {
        const expressions = domain ? getValidExpressions(this.expressions, domain) : this.expressions;
        if (expressions.length === 0)
            return undefined;
        return expressions.join(' AND ');
    }
    debug() {
        console.log(this.expressions);
    }
}
export default ExpressionBuilder;
