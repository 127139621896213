import React from 'react';
import { styled } from 'styletron-react';
import { IconButton } from '@fluentui/react';
import { EditRoleReportPanel } from '../../molecules/EditRoleReportPanel/EditRoleReportPanel';
const StyledFlexRow = styled('div', () => ({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
}));
const StyledEditButton = styled(IconButton, () => ({
    height: '100%',
}));
export const EditRoleReportCellRenderer = ({ api, node }) => {
    const rowData = node.data;
    const [isPanelOpen, setPanelOpen] = React.useState(false);
    const closePanel = () => {
        setPanelOpen(false);
    };
    const openPanel = () => {
        setPanelOpen(true);
    };
    const onSubmitAndClose = () => {
        api.refreshServerSide({ purge: true });
        closePanel();
    };
    if (!rowData) {
        return null;
    }
    return (React.createElement(StyledFlexRow, null,
        React.createElement(StyledEditButton, { ariaLabel: "Edit", iconProps: { iconName: 'Edit' }, onClick: openPanel, title: "Edit" }),
        React.createElement(EditRoleReportPanel, { id: rowData === null || rowData === void 0 ? void 0 : rowData.Id, isOpen: isPanelOpen, onCancel: closePanel, onSubmitAndClose: onSubmitAndClose, reportId: rowData === null || rowData === void 0 ? void 0 : rowData.ReportId })));
};
export default EditRoleReportCellRenderer;
