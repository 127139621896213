import { QueryExecuteRequestBuilder } from '../QueryExecuteRequestBuilder/QueryExecuteRequestBuilder';
export class ReportsExecuteRequestBuilder {
    constructor() {
        this.queryExecuteRequestBuilder = new QueryExecuteRequestBuilder('REPORT_Report')
            .withAdHocListViewMembers([
            {
                scalarDisplayName: 'DataLevel',
                scalarExpression: '[DataLevel]',
            },
            {
                scalarDisplayName: 'Description',
                scalarExpression: '[Description]',
            },
            {
                scalarDisplayName: 'DomainId',
                scalarExpression: '[DomainId]',
            },
            {
                scalarDisplayName: 'FilterId',
                scalarExpression: '[FilterId]',
            },
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'ListViewId',
                scalarExpression: '[ListViewId]',
            },
            {
                scalarDisplayName: 'Name',
                scalarExpression: '[Name]',
            },
            {
                scalarDisplayName: 'ReportImageStrataId',
                scalarExpression: '[ReportImageStrataId]',
            },
            {
                scalarDisplayName: 'ReportTemplateStrataId',
                scalarExpression: '[ReportTemplateStrataId]',
            },
            {
                scalarDisplayName: 'ReportTypeId',
                scalarExpression: '[ReportTypeId]',
            },
            {
                scalarDisplayName: 'ValidationExpression',
                scalarExpression: '[ValidationExpression]',
            },
            {
                scalarDisplayName: 'ValidationText',
                scalarExpression: '[ValidationText]',
            },
            {
                scalarDisplayName: 'IncludesCurrencyExchange',
                scalarExpression: '[IncludesCurrencyExchange]',
            },
            {
                scalarDisplayName: 'TargetCurrency',
                scalarExpression: '[TargetCurrency]',
            },
            {
                scalarDisplayName: 'ReportPeriodStart',
                scalarExpression: '[ReportPeriodStart]',
            },
            {
                scalarDisplayName: 'Source',
                scalarExpression: '[Source]',
            },
        ])
            .withFilterExpression('[IsActive] == true');
    }
    withFilterExpression(expression) {
        this.queryExecuteRequestBuilder.withFilterExpression(expression);
        return this;
    }
    withFilterById(id) {
        this.queryExecuteRequestBuilder.withFilterExpression(`[Id] = '${id}'`);
        return this;
    }
    withFilterByOptionalPortfolioId(portfolioId) {
        this.queryExecuteRequestBuilder.withFilterExpression(`(ISNULLOREMPTY([Optional_Portfolio_Ids]) OR DOESCONTAIN('${portfolioId}', [Optional_Portfolio_Ids]))`);
        return this;
    }
    withPaging(start, stop) {
        this.queryExecuteRequestBuilder.withPaging(start, stop);
        return this;
    }
    withSorting(order, orderBy) {
        this.queryExecuteRequestBuilder.withSorting(order, orderBy);
        return this;
    }
    build() {
        return this.queryExecuteRequestBuilder.build();
    }
    debug() {
        console.log(JSON.stringify(this.queryExecuteRequestBuilder, undefined, 2));
    }
}
export default ReportsExecuteRequestBuilder;
