import * as React from 'react';
import {
  ServiceDrivenViewSet,
  GridSelectionProvider,
  getTabRedirectString,
  ViewSetSubtabChangedEvent,
} from '@samc/screen-config-core';
import { ErrorBoundary } from '@samc/react-ui-core';
import { EditingProvider } from '@samc/react-ui-grid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  HeaderContextProvider,
  RoutingWrapper,
  useHeaderContext,
  useRouterBlockerAndPromptForUnsavedChanges,
} from '@samc/react-ui-history';

import { EvaluatorConfigurationProvider } from '@samc/expressions-react';

interface ViewSetProps {
  onTabChanged: (newId: string) => void;
  onSubtabChanged: (ev: ViewSetSubtabChangedEvent) => void;
  tabId?: string;
  subtabId?: string;
}

const ViewSetMain = (viewSetProps: ViewSetProps): React.ReactElement => {
  const { tabId, onTabChanged, subtabId, onSubtabChanged } = viewSetProps;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  useRouterBlockerAndPromptForUnsavedChanges();

  const primaryKeyValue = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('primaryKeyValue') || '';
  }, [location.search]);

  const handleSetPrimaryKey = React.useCallback(
    (value: string | undefined): void => {
      const params = new URLSearchParams(location.search);

      if (value) {
        params.set('primaryKeyValue', value);
        navigate(`${location.pathname}?${params.toString()}`);
      }
    },
    [location.pathname, location.search, navigate],
  );

  return (
    <ErrorBoundary>
      <div style={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto' }}>
        {id ? (
          // key here is set this way so that when the porfolio filter is changed, it will recreate/reload the data
          <ServiceDrivenViewSet
            key={id}
            viewSetId={id}
            primaryKeyValue={primaryKeyValue}
            setPrimaryKeyValue={handleSetPrimaryKey}
            initialSelectedTabId={tabId}
            onTabChanged={onTabChanged}
            initialSelectedSubtabId={subtabId}
            onSubtabChanged={onSubtabChanged}
          />
        ) : (
          <div>Please specify a ViewSet Id.</div>
        )}
      </div>
    </ErrorBoundary>
  );
};

const ViewSetPageInner = (): React.ReactElement => {
  const originalLocation = useLocation();
  const { tabId: urlTabId, subtabId: urlSubtabId } = useParams();

  const navigate = useNavigate();
  const { tabs } = useHeaderContext();

  const onTabIdChanged = React.useCallback(
    (newId: string, userRequested?: boolean) => {
      if (newId === urlTabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: newId,
          newSubtabId: undefined, // should clear it on tab change
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  const onSubtabIdChanged = React.useCallback(
    (ev: ViewSetSubtabChangedEvent) => {
      const { newId, userRequested } = ev;

      if (newId === urlSubtabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: urlTabId,
          newSubtabId: newId,
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  const onTabIndexChanged = React.useCallback(
    (newIndex: number, userRequested: boolean) => {
      if (!tabs) return;

      const tab = tabs[newIndex];
      if (!tab) return;

      onTabIdChanged(tab.id, userRequested);
    },
    [onTabIdChanged, tabs],
  );

  return (
    <EditingProvider>
      <GridSelectionProvider>
        <RoutingWrapper onTabChanged={onTabIndexChanged}>
          <ViewSetMain
            subtabId={urlSubtabId}
            onSubtabChanged={onSubtabIdChanged}
            tabId={urlTabId}
            onTabChanged={onTabIdChanged}
          />
        </RoutingWrapper>
      </GridSelectionProvider>
    </EditingProvider>
  );
};

export const ViewSetPage = (): React.ReactElement => {
  return (
    <div style={{ flex: 1 }}>
      <EvaluatorConfigurationProvider value={{}}>
        <HeaderContextProvider>
          <ViewSetPageInner />
        </HeaderContextProvider>
      </EvaluatorConfigurationProvider>
    </div>
  );
};

export default ViewSetPage;
