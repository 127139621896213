import { ExecuteLiftAndSiftQuery } from '../../functions/ExecuteLiftAndSiftQuery/ExecuteLiftAndSiftQuery';
import { useApplicationContext } from '../useApplicationContext/useApplicationContext';
export const useExecuteLiftAndSiftQuery = () => {
    const { LiftSiftApi, requestInit, ShowMessage } = useApplicationContext();
    const showError = (error) => {
        ShowMessage(error, 'error');
    };
    return [
        (requestBody) => ExecuteLiftAndSiftQuery(LiftSiftApi, requestBody, requestInit, showError),
    ];
};
export default useExecuteLiftAndSiftQuery;
