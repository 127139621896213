var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { styled } from 'styletron-react';
import { IconButton, Shimmer } from '@fluentui/react';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { REPORTS_KEY } from '../../hooks/useReports/useReports';
import { ReportsExecuteRequestBuilder } from '../../builders/ReportsExecuteRequestBuilder/ReportsExecuteRequestBuilder';
import { RoleReportsExecuteRequestBuilder } from '../../builders/RoleReportsExecuteRequestBuilder/RoleReportsExecuteRequestBuilder';
import { ExpressionBuilder } from '../../builders/ExpressionBuilder/ExpressionBuilder';
const StyledEditButton = styled(IconButton, () => ({
    height: '100%',
}));
const StyledFlexRow = styled('div', () => ({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
}));
const StyledShimmer = styled(Shimmer, () => ({
    height: '100%',
    padding: '2px',
}));
export const ExportRoleReportCellRenderer = ({ portfolioId, node }) => {
    const { QueryClient, SystemRoleReportDomainId, SystemPortfolioIdExpression, SystemPortfolioIdExpressionAlt, SystemPortfolioIdsExpression, useDomainGetter, useExecuteLiftAndSiftQuery, useGenerateReportMutation, } = useApplicationContext();
    const [executeQuery] = useExecuteLiftAndSiftQuery();
    const [generateReportMutation] = useGenerateReportMutation();
    const getDomain = useDomainGetter();
    const [isSaving, setIsSaving] = React.useState(false);
    const exportReport = () => __awaiter(void 0, void 0, void 0, function* () {
        const rowData = node.data;
        try {
            setIsSaving(true);
            const reportPromise = QueryClient.fetchQuery([REPORTS_KEY, rowData.ReportId], () => executeQuery(new ReportsExecuteRequestBuilder().withFilterById(rowData.ReportId).build()), { retry: false });
            const roleReportPromise = QueryClient.fetchQuery([REPORTS_KEY, rowData.Id], () => executeQuery(new RoleReportsExecuteRequestBuilder(SystemRoleReportDomainId).withFilterById(rowData.Id).build()), { retry: false });
            const [reportResponse, roleReportResponse] = yield Promise.all([reportPromise, roleReportPromise]);
            const report = reportResponse.Data[0];
            const roleReport = roleReportResponse.Data[0];
            const domain = yield getDomain(false, report.DomainId);
            const filterExpression = new ExpressionBuilder(`${SystemPortfolioIdExpression} = '${portfolioId}'`)
                .and(`${SystemPortfolioIdExpressionAlt} = '${portfolioId}'`)
                .and(`DOESCONTAIN('${portfolioId}', ${SystemPortfolioIdsExpression})`)
                .and(roleReport.FilterExpression)
                .build(domain);
            yield generateReportMutation.mutateAsync({
                domainId: report.DomainId,
                viewId: report.ListViewId,
                reportId: report.Id,
                filterExpression,
                templateId: report.ReportTemplateStrataId,
                sortOrder: undefined,
                sortOrderBy: undefined,
                filterIds: undefined,
                includesCurrencyExchange: report.IncludesCurrencyExchange,
                reportPeriodStart: roleReport.ReportPeriodStart,
                source: roleReport.Source,
                targetCurrency: roleReport.TargetCurrency,
            });
        }
        catch (e) {
            console.error(e);
        }
        setIsSaving(false);
    });
    if (!node.data) {
        return null;
    }
    if (isSaving) {
        return (React.createElement(StyledShimmer, { "data-testid": "shimmer-ExportReportCellRenderer", styles: { shimmerWrapper: { height: '100% !important' } } }));
    }
    return (React.createElement(StyledFlexRow, null,
        React.createElement(StyledEditButton, { ariaLabel: "Generate", iconProps: { iconName: 'Generate' }, onClick: exportReport, title: "Generate" })));
};
export const GetExportRoleReportCellRendererForPortfolioId = (portfolioId) => (props) => {
    return ExportRoleReportCellRenderer(Object.assign(Object.assign({}, props), { portfolioId }));
};
export default ExportRoleReportCellRenderer;
