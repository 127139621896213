import React from 'react';
import { styled } from 'styletron-react';
const StyledFlexRow = styled('div', () => ({
    alignItems: 'center',
    backgroundColor: '#e6e6e6',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '7px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '7px',
}));
export const PageHeader = ({ children }) => {
    return React.createElement(StyledFlexRow, null, children);
};
export default PageHeader;
