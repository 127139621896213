import React from 'react';
import { MessageBar } from '@samc/react-ui-core';
import { styled } from 'styletron-react';
import { ETab, ReportCatalogPage as BaseReportCatalogPage } from './ReportCatalogPage.Tabs';

/* istanbul ignore next */
const StyledRoot = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

interface Props {
  portfolioId: string | null;
  activeTab: ETab;
}

export const ReportCatalogPage = ({ activeTab, portfolioId }: Props): React.ReactElement => {
  if (!portfolioId) {
    return <MessageBar text="Select a Portfolio from the dropdown..." />;
  }

  return (
    <StyledRoot>
      <BaseReportCatalogPage activeTab={activeTab} portfolioId={portfolioId} />
    </StyledRoot>
  );
};

export default ReportCatalogPage;
