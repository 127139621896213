export var Status;
(function (Status) {
    Status[Status["Active"] = 1] = "Active";
    Status[Status["Inactive"] = 0] = "Inactive";
})(Status || (Status = {}));
export var ViewID;
(function (ViewID) {
    ViewID["CloneReportToPortfolioReport"] = "CloneReportToPortfolioReport";
    ViewID["CloneReport"] = "CloneReport";
})(ViewID || (ViewID = {}));
