var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const Fetch = (input_1, init_1, ...args_1) => __awaiter(void 0, [input_1, init_1, ...args_1], void 0, function* (input, init, ignoreStatus = []) {
    const response = yield fetch(input, init);
    if (response.status >= 400 && response.status < 600 && ignoreStatus.indexOf(response.status) === -1) {
        if (response.status === 401) {
            throw new Error(`Unauthorized. Please log out and back in again.`);
        }
        if (response.status === 403) {
            throw new Error(`Request Forbidden. Please contact your administrator if you believe this is an error.`);
        }
        if (response.status === 502) {
            throw new Error(`Request failed due to temporary outage. Please try again later.`);
        }
        if (response.status === 504) {
            throw new Error(`Request timed out. Please refresh the page and try again.`);
        }
        const body = yield response.json();
        if (body.developerMessage) {
            throw new Error(`${response.statusText}: ${body.developerMessage.message}`);
        }
        if (body.messages && body.messages.length > 0) {
            throw new Error(`${body.messages[0]}`);
        }
    }
    return response;
});
export default Fetch;
