import React from 'react';
import { CronField as CronFieldCore } from '@samc/react-ui-form';
export const CronField = (props) => {
    const { hasError, onChange: onFieldChange, value } = props;
    const onChange = (cronExpression) => {
        onFieldChange(cronExpression);
    };
    return React.createElement(CronFieldCore, { cronFormat: 5, hasError: hasError, initialValue: value, onChange: onChange });
};
export default CronField;
